@import "../../../Global_SCSS/base";
.otp-inner-container {
  display: flex;
  flex-direction: column;
}
.light-grey-text {
  font-size: 14px;
  color: #6b7280;
  line-height: 20px;
}
.email-text {
  color: #374151;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
}
.light-black-text {
  color: #111827;
  font-size: 14px;
  line-height: 20px;
}
.grey-normal-text {
  color: #6b7280;
  font-size: 16px;
  line-height: 24px;
}
.resend-button {
  color: #111827;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  cursor: pointer;
}
.containerStyle {
  display: flex;
  justify-content: flex-start;
  // width: 50%;
  gap: 16px;
}
.inputStyle {
  background-color: #f9fafb;
  border-radius: 8px;
  border: 1px solid #d1d5db;
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
  height: 3.5rem;
  width: 3.5rem;
}

.inputStyle:focus {
  background-color: #e5e7eb;
  border: 1px solid #111827;
}

.inputStyle:not([value=""]):not(:focus) {
  background-color: #e5e7eb;
}

.resend-button-disabled {
  color: #e5e7eb;
  cursor: default;
}
