@import "../../../Global_SCSS/base";
.monogram-container {
  margin-top: 55px;
}
@media screen and (min-width: 360px) and (max-width: 540px) {
  .monogram-container {
    display: none;
  }
}
@media screen and (min-width: 280px) and (max-width: 350px) {
  .monogram-container {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .monogram-container {
    display: none;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1366px) {
  .monogram-container {
    height: 120px;
  margin-top: 120px;

  }
}

