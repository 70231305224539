@import "../../../Global_SCSS/base";

.header-logo {
  width: 267px;
  height: 58px;
}

@media screen and (min-width: 360px) and (max-width: 540px)
{
  .header-logo {
    width: 145px;
    height: 27px;
  }
}
@media screen and (min-width: 280px) and (max-width: 350px)
{
  .header-logo {
    width: 145px;
    height: 27px;
  }
}
