@import "../../../Global_SCSS//base";
.footer-links-container {
  margin-left: 50px;
  margin-right: 50px;
  display: flex;
  justify-content: space-between;
}

.bottom-links {
  display: flex;
  gap: 1.5rem;
}

.small-black-text {
  font-size: 14px;
  line-height: 21px;
  text-decoration: underline;
}
.small-black-text-simple {
  font-size: 14px;
}

@media screen and (max-width: 430px) {
  .footer-links-container {
    margin-top: 40px;
    margin-left: 30px;
    margin-right: 30px;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  .small-black-text {
    font-size: 11px;
    line-height: 14px;
    text-decoration: underline;
  }
  .small-black-text-simple {
    font-size: 11px;
    line-height: 36px;
  }
}
@media screen and (max-width: 768px)
{
  .footer-links-container {
    margin-top: 40px;
    margin-left: 30px;
    margin-right: 30px;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
}
