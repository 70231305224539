@import "../../../Global_SCSS/base";
.user-management-login-page {
  background-color: #4d5562;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}
.user-management-poppup {
  display: flex;
  flex-direction: column;
  width: 30%;
  gap: 1%;
}
.user-management-login {
  background-color: $white-00;
  // margin: auto;
  height: auto;
  // padding: 22px 32px 22px 24px;
  padding: 1.375rem 2rem 1.375rem 1.5rem;

  border-radius: 8px;
}
.cross-icon {
  display: flex;
  margin-bottom: 2%;
  justify-content: flex-end;
  cursor: pointer;
}

.user-management-login-text {
  color: $black--0;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  font-family: Inter;
  padding-top: 2.1%;
  padding-bottom: 4%;
  margin-bottom: 5%;
  border-bottom: 1px solid $white--1;
}
.user-login-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  gap: 24px;
}
.login-form-errors {
  width: 398px;
  height: auto;
  margin-top: 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.user-management-button {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 3%;
  margin-bottom: 3%;
  padding: 5% 2% 0% 0;
  border-top: 1px solid $white--1;
}
.send-button {
  padding: 9px 22px 9px 22px;
  color: $white-00;
  background-color: $green;
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  border: none;
  cursor: pointer;
}
.send-button:disabled {
  cursor: default;
  background-color: #cbcbcb;
}
.cancel-button {
  padding: 9px 17px 9px 17px;
  background-color: $white-00;
  color: $black--1;
  border: 1px solid $white--1;
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.user-management-input-field {
  outline: none;
  box-sizing: border-box;
  width: 100%;
  height: 38px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
  border: 1px solid $white--1;
  border-radius: 6px;
}

.user-management-login-form-errors {
  background-color: rgba(255, 255, 255, 0.9);

  width: auto;
  height: auto;
  margin-top: 10px;
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  gap: 24px;
}
.input-form-field-error-user-management {
  outline: none !important;
  border-radius: 6px;
  width: 100%;
  height: 38px;
  border: 3px solid #c52a1a;
  background-color: rgba(255, 255, 255, 0.9);
}
@media screen and (min-width: 360px) and (max-width: 540px) {
  .user-management-poppup {
    width: 75%;
    margin-bottom: 150px;
  }
  .user-management-login {
    padding: 0.2rem 0.8rem;
    border-radius: 6px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .user-management-login-page {
   
    align-items:flex-start;
  
  }
  .user-management-poppup {
   margin-top: 100px;
    width: 50%;
  }
}

@media screen and (min-width: 280px) and (max-width: 350px) {
 
  .user-management-poppup {
   
    width: 80%;
  }
}


