@import "../../../Global_SCSS/base";
.user-login-page-background {
  background: url("../../../Assets/ClientSignInBG.png");
  width: 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.user-login-page-overlay {
  background: url("../../../Assets/ClientSinInGradient.png");
  position: absolute;

  left: 50%;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
}
.user-background-text {
  color: white;
  font-family: Inter;
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
  position: absolute;
  bottom: 20%;
  left: 8%;
  width: 80%;
}
.learn-more {
  position: absolute;
  font-size: 20px;
  bottom: 10%;
  left: 8%;
  background: white;
  color: black;
  text-align: center;
  border: none;
  padding: 15px 20px 15px 20px;
  width: 150px;
  border-radius: 6px;
  white-space: nowrap;
}

@media screen and (min-width: 360px) and (max-width: 540px) {
  .user-login-page-background {
    height: 50%;
    width: 100%;
  }
  .user-login-page-overlay {
    left: 0;
    right: 0;
    top: 0;
    bottom: 50%;
  }
  .user-background-text {
    font-size: 36px;
    font-weight: 600;
    line-height: 43px;
    position: absolute;
    bottom: 30%;
  }
}
@media screen and (min-width: 280px) and (max-width: 350px) {
  .user-login-page-background {
    height: 50%;
    width: 100%;
  }
  .user-login-page-overlay {
    left: 0;
    right: 0;
    top: 0;
    bottom: 50%;
  }
  .user-background-text {
    font-size: 36px;
    font-weight: 600;
    line-height: 43px;
    position: absolute;
    bottom: 30%;
  }
}


@media screen and (min-width: 768px) and (max-width: 1024px) {
  .user-background-text {
    bottom: 55%;
  }
  .learn-more {
    bottom: 45%;
  }
}
@media screen and (min-width: 280px) and (max-width: 350px) {
  .user-background-text {
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    position: absolute;
    bottom: 30%;
  }
}
