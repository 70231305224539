@import "../../../Global_SCSS/base";

.Search {
  display: flex;
  gap: 24px;
  margin-top: 0;
}

.top-header {
  display: flex;
  justify-content: space-between;
  gap: 53%;
  align-items: center;
}

.search-field {
  gap: 1rem;
}

.search-heading {
  padding: 1.2rem 0 1.2rem 0;
 
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: #111827;
}

.SearchField {
  display: flex;
    border: 1px solid #d1d5db;
    border-radius: 6px;

}

.InputField {
  justify-self: flex-start;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  outline: none;
  height: auto;
  padding-left: 5px;
  min-width: 20vw;
  

}

.SearchIcon {
  padding-right: 10px;

  display: flex;
  align-self: center;
}

@media screen and (min-width: 360px) and (max-width: 768px) {
  .Search {
  flex-wrap:wrap;
  }
  .SearchField {
    display: flex;
      border: 1px solid #d1d5db;
      border-radius: 6px;
      height: 40px;  
  }
}

@media screen and (min-width: 280px) and (max-width: 350px) {
  .Search {
  flex-wrap:wrap;
  }
  .SearchField {
    display: flex;
      border: 1px solid #d1d5db;
      border-radius: 6px;
      height: 40px;  
  }
  
}