@import "../../../Global_SCSS/base";

.sign-up-form-parent {
  background-color: #ffffff;
  width: 50%;
}
.sign-up-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.sign-up-page-bottom-text {
  display: flex;
  justify-content: center;
}

.user-sign-up-form-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 34px;
}

@media screen and (min-width: 360px) and (max-width: 540px) {
  .sign-up-form-parent {
    width: 100%;
    height: 50vh;
  }
  .user-sign-up-form-header {
    gap: 24px;
  }
}

@media screen and (min-width: 280px) and (max-width: 350px) {
  .sign-up-form-parent {
    width: 100%;
    height: 50vh;
  }
  .sign-up-page {
   
    justify-content: unset;
   
  }
  .user-sign-up-form-header {
    gap: 24px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .sign-up-page {
    justify-content: start;
    gap: 150px;
    padding-top: 30px;
  }
  .user-sign-up-form-header {
    gap: 4px;
  }
}
