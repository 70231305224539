@import "../../../Global_SCSS/base";

.create-new-order-form {
  margin-top: 2%;
  width: 75%;
}

.order-form-container {
  display: flex;
  width: 100%;
}
.create-order-form-button {
  display: flex;
  justify-content: flex-start;
  gap: 2%;
  margin-top: 2%;
  margin-bottom: 1%;

  cursor: pointer;
}

.text-area-create {
  display: flex;
  height: 105px;
  outline: none;
  width: 98%;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid $white--1;
  background: var(--Color-Gray-white, #fff);
  padding: 0.5rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
  overflow: hidden;
  // margin-bottom: 10%;
  resize: none;
}
.text-area-create-error {
  border-color: red;
  border: 3px solid #c52a1a;
}

.select-price {
  color: $green;
  font-size: 14px;
  font-weight: 400;
  font-family: Inter;
}

.button-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.create-form-button-container {
  display: grid;
  grid-template-columns: 1fr;
}

.width-40 {
  width: 60%;
}

.width-30 {
  width: 30%;
}

.light-grey-box {
  background-color: #f3f4f6;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 4%;
  border-radius: 8px;
  padding: 1.5rem 1.1rem 1.8rem 1.5rem;
  width: 20%;
  height: 10%;
}
.light-grey-box-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.simple-grey-text {
  color: #4d5562;
  font-size: 24px;
  line-height: 28px;
  font-family: Inter;
  font-weight: 600;
}

.small-icon {
  width: 23px;
  height: 23px;
}

.add-field-button {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  cursor: pointer;
}

.simple-green-text {
  color: #059669;
  font-family: Inter;
  font-size: 16px;
  line-height: 24px;
}

.bold-green-text {
  color: #059669;
  font-size: 40px;
  font-weight: 600;
  font-family: Inter;
}
.asterisk {
  color: red;
  font-size: 20px;
}

@media screen and (min-width: 280px) and (max-width: 350px) {
  .light-grey-box {
    display: none;
    // gap: 0.5rem;
    // margin-top: 4%;
    // border-radius: 6px;
    // padding: 0.5rem 0.4rem 0.6rem 0.5rem;
    // width: 20%;
    // height: 10%;
  }
  .order-form-container {
    margin-top: 12px;
  }
  .simple-grey-text {
    font-size: 12px;
    line-height: 20px;
  }
  .small-icon {
    width: 15px;
    height: 15px;
  }
  .bold-green-text {
    font-size: 16px;
  }
  .create-order-form-button {
    margin-bottom: 38%;
  }
  .create-new-order-form {
    width: 95%;
  }
  .width-40 {
    width: 100%;
  }
}

@media screen and (min-width: 360px) and (max-width: 540px) {
  .light-grey-box {
    display: none;
    // gap: 0.5rem;
    // margin-top: 4%;
    // border-radius: 6px;
    // padding: 0.5rem 0.4rem 0.6rem 0.5rem;
    // width: 20%;
    // height: 10%;
  }
  .simple-grey-text {
    font-size: 12px;
    line-height: 20px;
  }
  .small-icon {
    width: 15px;
    height: 15px;
  }
  .bold-green-text {
    font-size: 16px;
  }
  .create-order-form-button {
    margin-bottom: 38%;
  }
  .create-new-order-form {
    width: 95%;
  }
  .width-40 {
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .light-grey-box {
   
    gap: 1.5rem;
    margin-top: 4%;
    border-radius: 6px;
    padding: 0.5rem 0.75rem 0.6rem 0.5rem;
    width: 24%;
    height: 7%;
  }
  .create-order-form-button {
    margin-bottom: 92%;
  }
  .width-40 {
    width: 90%;
  }
  .create-new-order-form {
    width: 95%;
    margin-bottom: 50px;
  }
  .bold-green-text {
    font-size: 26px;
  }
}
