.login-container-forgot-pass {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: white; 
  width: 50%;
}
.forgot-pass-user {
  display: flex;
}
.forgot-password-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.logo-forgot-pass {
  width: 137px;
  margin-bottom: 2.38rem;
}
.login-form-forgot-pass {
  // padding-top: 15%;
  // padding-bottom: 15%;
  background-color: rgba(255, 255, 255, 0.9);
  // padding: 32px 40px 32px 40px;
  // width: 448px;
  height: auto;
  // margin-top: 10px;
  // border-radius: 8px;

  display: flex;
  flex-direction: column;
  gap: 24px;
}
.login-form-errors-forgot-pass {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 32px 40px 32px 40px;
  width: 448px;
  height: 216px;
  margin-top: 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 24px;
}

// label {
//   display: block;
//   margin-bottom: 8px;
//   color: var(--Color-Gray-700, #374151);
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 24px;
// }
.input-form-field-forgot-pass {
  padding:0;
  width: 100%;
  height: 38px;
  border-radius: 8px;
  border: 1px solid #d1d5db;
  color: var(--Color-Gray-700, #374151);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.input-form-field-forgot-pass:focus {
  outline: none !important;
  width: 100%;
  height: 38px;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #d1d5db;
  /* margin-bottom: 16px; */
}
.input-form-field-error-forgot-pass {
  width: 100%;
  height: 38px;
  padding: 8px;
  border-radius: 8px;
  border: 3px solid #c52a1a;
}
.input-form-field-error-forgot-pass:focus {
  outline: none !important;
  width: 100%;
  height: 38px;
  padding: 8px;
  border-radius: 8px;
  border: 3px solid #c52a1a;
}
.login-container-forgot-pass h2 {
  color: #394150;

  margin-top: 0px;
  padding: 1px;
}

.forgot-pass-bottom-text {
  color: #394150;
  padding-top: 10%;
  padding-bottom: 2%;
  text-align: center;
}

.signin-form-submit {
  background-color: #059669;
  color: #fff;
  padding: 10px;
  width: 100%;
  height: 38px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}
.reset-password-email {
  width: 350px;
  color: #4d5562;
  margin-top: 10%;
}
