@import "../../../Global_SCSS/base";

.black-bold-heading {
  padding: 1.2rem 0 1.2rem 35px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: #111827;
}

@media screen and (max-width: 430px) {
  
  .black-bold-heading {
    padding: 1rem 0 1.2rem 0px;
    
  }
}
