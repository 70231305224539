@import "../../../Global_SCSS/base";
.text-area {
  width: 60%;
  height: 100px;
  padding: 0.5rem 0.8rem;
  margin-top: 0.6rem;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  margin-bottom: 2.5rem;
  resize: none;
}
.text-area::placeholder {
  color: #374151;
  font-family: Inter;
  font-weight: 500;
  font-size: 14px;
}

.text-area-title {
  color: #374151;
  font-family: Inter;
  font-weight: 500;
  font-size: 14px;
}
