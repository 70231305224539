.upload-file-button {
  padding: 9px 20px 9px 20px;
  color: #ffffff;
  background-color: #059669;
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  border: none;
  font-size: 12px;
  cursor: pointer;
}
