/** @format */

body {
  margin: 0;
}
.create-new-pass-user {
  display: flex;
}
.create-newpass-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: white;
  width: 50%;
}
.logo {
  width: 137px;
  height: 113px;
  margin-bottom: 50px;
  background-repeat: no-repeat;
}
.create-new-password-title {
  font-family: Inter;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #394150;
}
.login-form-create-pass {
  background-color: rgba(255, 255, 255, 0.9);
  width: 448px;
  height: auto;
  margin-top: 10px;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  gap: 18px;
}
.login-form-errors-create-pass {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 32px 40px 32px 40px;
  width: 448px;
  height: 330px;
  margin-top: 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 24px;
}
label {
  display: block;
  margin-bottom: 8px;
  color: var(--Color-Gray-700, #374151);
  /* H12 - Small/leading-5/font-medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.input-form-field {
  width: 100%;
  outline: none !important;
  height: 38px;
  padding-left: 10px;
  border-radius: 6px;
  border: 1px solid #d1d5db;
}
.input-form-field-error {
  width: 100%;
  border-radius: 8px;
  border: 3px solid #c52a1a;
}
.input-form-field-error:focus {
  outline: none !important;
  width: 100%;
  border-radius: 8px;
  border: 3px solid #c52a1a;
}

//
.remember-me {
  display: flex;
  gap: 10px;
  align-items: center;
}

/* .remember-me input {
      margin-right: 5px; 
    } */
text {
  color: #9e6c3c;
}

.formbuttons {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.create-new-pass-bottom-text {
  color: #ffffff;
  padding-top: 150px;
  text-align: center;
}

input[type="checkbox"] {
  background-color: #fff;
}
.error-color {
  color: red;
  margin: 0px;
}
.new-password-inner-container
{
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 40px 32px 40px;

}
@media screen and (max-width: 1024px) {
  .logo {
    width: 100px;
    height: 80px;
    margin-bottom: 30px;
  }

  .login-form-create-pass,
  .login-form-errors-create-pass {
    width: 80%;
    max-width: 400px;
    height: auto;
    margin-top: 20px;
  }

  .input-form-field,
  .input-form-field-error {
    width: 100%;
    margin-bottom: 16px;
  }
  @media screen and (min-width: 360px) and (max-width: 540px) {
    .input-form-field,
  .input-form-field-error {
    width: 100%;
    margin-bottom: 1px;
  }
  }
}

@media screen and (min-width: 280px) and (max-width: 350px) {
  .input-form-field,
.input-form-field-error {
  width: 100%;
  margin-bottom: 1px;
}
}


@media screen and (min-width: 768px) and (max-width: 1024px)
{
  .input-form-field-error {
    width: 100%;
    margin-bottom: 0px;
  }
}
