@import "../../../Global_SCSS/base";

.comment-section {
  display: flex;
  flex-direction: column;
  gap: 2.1rem;
}

.text-area {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
}
