@import "../../../Global_SCSS/base";
.button-card-inner-container
{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    font-size: 20px;
    padding-right: 10%;
    line-height: 15px;
    color: #059669;
    font-weight: 600;
    cursor: pointer;
}

.button-card-inner-container img {
    width: 1.5rem;
    height: 1.5rem;
}

@media screen and (min-width: 360px) and (max-width: 540px) {
    .button-card-inner-container
    {
        gap: 2.2rem;
      
    }
  }