@import "../../../Global_SCSS/base";
.create-order-form {
  background-color: $white-00;
  border-radius: 8px;
  padding: 1% 3%;
  margin: 0 2% 4% 2%;
}
.active-step {
  border-bottom: 4px solid $green;
  padding-bottom: 0;
  color: $green;
  font-size: 16px;
  font-weight: 400;
}
.create-new-order-fomr_title {
  font-family: Inter;
  font-weight: 600;
  font-size: 24px;
  color: $black-3;
  border-bottom: 1px solid $white--1;
  padding: 20px 0px 25px 0px;
}
.order-information {
  width: 45%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d1d5db;
}
.order-view-kind {
  display: flex;
  gap: 5%;
  padding: 8% 2% 2% 1%;
  white-space: nowrap;
}
.order-details-link {
  color: #4d5562;
  font-size: 16px;
  font-weight: 400;
}
.create-order-stepper {
  background-color: #f3f4f6;
}
@media only screen and (max-width: 1200px) {
  .order-information {
    width: 55%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px)
{
  .order-information {
    width: 90%;
  }
}
@media screen and (min-width: 360px) and (max-width: 540px) {
  .order-information {
    flex-direction: column;
  }
}

@media screen and (min-width: 280px) and (max-width: 350px) {
  .order-information {
    flex-direction: column;
  }
}
