@import "../../../Global_SCSS/base";
.user-billing-page{
    display: flex;
    flex-direction: column;
    margin: 0px 3%;
    gap: 15px;
}
.pay-now-button
{
    font-size: 14px;
    font-weight: 500;
    line-height: 25px;
    color: #059669;
    text-underline-offset: 17%;
    text-decoration: underline;
    cursor: pointer;
}

.billing-download-button
{
background-color: #F3F4F6;
color: #1F2937;
line-height: 25px;
padding: 2px 12px;
border: none;
border-radius: 6px;
cursor: pointer;
}

