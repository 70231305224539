// SettingAccount.scss

.setting-account-container {
  position: relative;
}

.setting-account-title {
  font-size: 24px;
  font-weight: 600;
  color: #212936;
  line-height: 34px;
}

.setting-account-avatar-container {
  position: relative;
  display: inline-block;
}

.setting-account-edit-icon-container {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.setting-account-edit-icon {
  width: 14px;
  height: 14px;
}

.setting-account-details {
  margin-top: 5%;
}

.setting-account-details-row {
  display: flex;
  width: 100%;
  margin-bottom: 2%;
  justify-content: space-between;
}

.setting-account-details-row-text {
  display: flex;
  width: 60%;
}

.setting-account-details-row-key {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #4b5563;
  text-transform: capitalize;
  width: 30%;
}

.setting-account-details-row-value {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #212936;
}

.setting-account-details-row-edit {
  cursor: pointer;
}

@media only screen and (max-width: 1025px) {
  .setting-account-details-row-text {
    width: 90%;
  }
}

@media screen and (max-width: 430px) {
  .setting-account-details-row-text {
  flex-direction: column;
  }
}
