@import "../../../Global_SCSS/base";
.user-title-text {
  font-size: 24px;
  font-weight: 600;
  color: #212936;
  padding: 2% 3% 2% 3%;
  line-height: 34px;
}

@media screen and (max-width: 430px) {
  .user-title-text {
    font-size: 20px;
    padding: 6% 3% 2% 3%;
  }
}
