/** @format */

.custom-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pagination-right {
  display: flex;
  padding-top: 1.1%;
  padding-bottom: 1.1%;
  padding-right: 1.9%;
}
.centerpage {
  color: #ffffff;
  background-color: #059669;
  width: 41px;
  height: 38px;
  border: 1px solid #059669;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination_button {
  color: #ffffff;
  background-color: #ffff;
  width: 41px;
  height: 38px;
  border: 1px solid #d1d5db;
  border-right-width: 0px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px 0px 0px 6px;
}

.pagination-left {
  color: #4b5563;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  padding-top: 1.9%;
  padding-left: 35px;
  padding-bottom: 1.9%;
  white-space: nowrap;
}

.pagination_button-next {
  color: #ffffff;
  background-color: #ffff;
  width: 41px;
  height: 38px;
  border: 1px solid #d1d5db;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 6px 6px 0px;
  border-left: 0;
}
.rotate-icon {
  transform: rotate(180deg);
}
.pagination_button:disabled,
.pagination_button-next:disabled {
  background-color: #ececec;
  cursor: default;
}
.pagination_button-next,
.pagination_button {
  cursor: pointer;
}

@media screen and (max-width: 430px) {
  .pagination-left {
    padding-left: 5px;
  }
  .pagination_button {
    width: 21px;
    height: 19px;
  border-radius: 3px 0px 0px 3px;

  }
  .pagination_button-next {
    width: 21px;
    height: 19px;
  border-radius: 0px 3px 3px 0px;

  }
  .centerpage {
    width: 21px;
    height: 19px;
  }
}
