/* CircularAvatar.css */

.circular-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circular-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.circular-avatar span {
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  color: #ffffff;
}

@media screen and (max-width: 430px) {
  
  .circular-avatar {
    width: 25px;
    height: 25px;
   
  }
  
  .circular-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .circular-avatar span {
    font-size: 10px;
    line-height: 10px;
  
  }
}
