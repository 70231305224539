.billing-setting-container {
  display: flex;
  gap: 2rem;
}
.billing-setting-header {
  display: flex;
  gap: 8%;
  padding: 0% 2% 2% 1%;
  white-space: nowrap;
  cursor: pointer;
}
.billing-setting-name {
  color: #4d5562;
  font-size: 16px;
  font-weight: 400;
}

.active-billing-setting-name {
  border-bottom: 3px solid #059669;
  padding-bottom: 1rem;
  color: #059669;
  font-size: 16px;
  font-weight: 400;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .active-billing-setting-name {
    font-size: 12px;
  }
  .billing-setting-name {
    font-size: 12px;
  }

}

@media screen and (min-width: 360px) and (max-width: 430px) {
  .active-billing-setting-name {
    font-size: 9px;
  }
  .billing-setting-name {
    font-size: 9px;
  }
}


@media screen and (min-width: 280px) and (max-width: 350px) {
  .active-billing-setting-name {
    font-size: 9px;
  }
  .billing-setting-name {
    font-size: 9px;
  }
}
