@import "../../../Global_SCSS/base";

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  // color: #444;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 13vh;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
}

.accordion__section {
  border-bottom: 1px solid #dedede;
}

.accordion_status_down_arrow {
  display: flex;
  column-gap: 10px;
}

/* Style the accordion content title */
.accordion__title {
  color: #012238;
  font-weight: 600;
  font-size: 20px;
}

/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  background-color: white;
  overflow: hidden;
  transition: max-height 0.6s ease;
}

/* Style the accordion content text */
.accordion__text {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 18px;
}

.accordian-text-area {
  width: calc(100% - 10px);
  height: 100px;
  margin-top: 20px;
  border: 1px solid #d1d5db;
  border-radius: 6px;
}

.accordian-text-area-title {
  padding-top: 4%;
  color: #374151;
  font-family: Inter;
  font-weight: 500;
  font-size: 14px;
  padding-bottom: 2%;
}

.accordian-text-area-title {
  padding-top: 4%;
  color: #374151;
  font-family: Inter;
  font-weight: 500;
  font-size: 14px;
  padding-bottom: 2%;
}

.arrow-icon-accordian {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 430px) {
  .accordion__title {
    font-weight: 600;
    font-size: 12px;
  }

.accordion_status_down_arrow {
  column-gap: 2px;
}
}
