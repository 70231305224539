@import "../../../Global_SCSS/base";
.user-order-card-container {
  display: flex;
  flex-direction: row;
  height: 100px;
  width: max-content;
  gap: 22px;
}
.card-date {
  font-size: 20px;
  line-height: 15px;
  color: #212936;
  font-weight: bold;
}

.card-invoice-total {
  font-size: 24px;
  line-height: 30px;
  color: #219653;
  font-weight: bold;
}

@media screen and (min-width: 360px) and (max-width: 430px) {
  .user-order-card-container {
    width: 100%;
  }
  .card-date {
    font-size: 19px;
    line-height: 25px;
    color: #212936;
    font-weight: bold;
  }
  .card-invoice-total {
    font-size: 14px;
    line-height: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .user-order-card-container {
    width: 100%;
  }
}
