@import "../../../Global_SCSS/base";
.product-sample-container {
  display: flex;
  flex-direction: column;
  width: 23%;
  gap: 0.5rem;
}
.product-sample-inner-container {
  display: flex;
  align-items: center;
  gap: 0.7rem;
}
.product-title {
  font-size: 18px;
  line-height: 25px;
  font-weight: 600;
  color: #24292f;
}
.product-detail-text {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-align: left;
  color: #57606a;
}

@media screen and (max-width: 540px) {
  .product-sample-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
  }
}

@media screen and (max-width: 1024px) {
  .product-title {
    font-size: 14px;
  }
  .product-detail-text {
    font-size: 16px;
    line-height: 18px;
  }
}

@media only screen and (max-width: 768px) {
  .product-title {
    font-size: 12px;
  }
  .product-detail-text {
    font-size: 13px;
    line-height: 16px;
  }
}
