.setting-password-title {
  font-size: 24px;
  font-weight: 600;
  color: #212936;
  line-height: 34px;
}

.setting-password-fields {
  display: flex;
  flex-direction: column;
  width: 70%;
  grid-gap: 20px;
  margin-top: 5%;
}

.setting-password-buttons {
  display: flex;
  gap: 4%;
}

@media only screen and (max-width: 1025px) {
  .setting-password-fields {
    width: 100%;
  }
  .setting-password-buttons {
    flex-direction: column;
    gap: 6px;
  }
}
