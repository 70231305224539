.dots-menu-container {
  position: relative;
  left: 0.8rem;
  display: inline-block;
}

.dots-menu-icon {
  cursor: pointer;
  font-weight: 700;
  font-size: 22px;
  color: #6c727f;
}

.dropdown-content-single {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 12px 20px 12px 16px;
  // top: 1.5rem;
  top: 100%;
  margin-top: -40px;
  right: 1rem;
  background-color: #f9f9f9;
  min-width: 6rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  font-size: 14px;
  line-height: 20px;
  max-width: 100%;
  border-radius: 6px;
  overflow-x: auto;
}
.dropdown-content-extended {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 12px 20px 9px 16px;
  top: 100%;
  margin-top: -90px;
  right: 1rem;
  background-color: #f9f9f9;
  min-width: 6rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  font-size: 14px;
  line-height: 20px;
  max-width: 100%;
  border-radius: 6px;
  overflow-x: auto;
}

.dropdown-content-extended > * {
  border-radius: 6px;
  padding: 0.1rem 0.1rem 0.1rem 0.4rem;

  color: #000;
  cursor: pointer;
}

.dropdown-content-single > * {
  border-radius: 6px;
  padding: 0.2rem 0.2rem 0.2rem 0.8rem;

  color: #000;
  cursor: pointer;
}

// .dropdown-content div {
//   padding: 12px 30px 12px 16px;
//   text-decoration: none;
//   display: block;
//   cursor: pointer;
// }

// .dropdown-content div:hover {
//   background-color: #f1f1f1;
// }
