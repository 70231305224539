.sort-box-inner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6c727f;

  gap: 0.5rem;
  border-radius: 0px 6px 6px 0px;
  background: var(--Color-Gray-50, #f9fafb);
  padding: 10px;
  height: max-content;
  border: 1px solid #d1d5db;
}

.sort_img {
  width: 20px;
  height: 12px;
}

.sort-box-inner-container ::after,
.sort-box-inner-container :focus {
  border: 1px solid #059669;
}

.sort-box-inner-container:hover {
  border: 1px solid #059669;
  cursor: pointer;
}
// .sort-box-inner-container::selection {
//   border: 1px solid #059669;
//   cursor: pointer;
// }



.sort-box-text {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 7px;
}

.sort-box-inner-container:active {
  border-color: #059669;
}

.sort-box-inner {
  cursor: pointer;
  height: 10px;
}

.sort-option {
  padding: 0;
}

.custom-sort {
  display: flex;
  width: 100px;
  flex-direction: column;
  justify-content: center;
  border-radius: 6px;
  background: var(--white, #fff);
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
  position: absolute;
  z-index: 100;
}
