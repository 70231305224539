@import "../../../Global_SCSS/base";

$rt-namespace: "Toastify";

$rt-mobile: "only screen and (max-width : 480px)" !default;

.#{$rt-namespace}__toast--error .#{$rt-namespace}__toast-body {
  color: red;
}

.#{$rt-namespace}__toast--error .#{$rt-namespace}__close-button svg {
  fill: red;
}

.#{$rt-namespace}__toast-theme--light.#{$rt-namespace}__toast--error {
  background: rgba(239, 227, 227, 0.98);
}

.#{$rt-namespace}__toast-theme--light {
  color: #065f46 !important;
}

.#{$rt-namespace}__toast-container {
  width: max-content !important;
  height: 52px !important;
}

.#{$rt-namespace}__progress-bar {
  position: absolute;

  width: 0 !important;
  height: 0 !important;

  &--bg {
    opacity: var(--toastify-color-progress-bgo);
    width: 0 !important;
    height: 0 !important;
  }
  &--success {
    background: var(--toastify-color-progress-success) !important;
  }
}
.#{$rt-namespace}__toast {
  --y: 0;
  position: relative;
  touch-action: none;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: var(--toastify-toast-bd-radius);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  max-height: var(--toastify-toast-max-height);
  font-family: var(--toastify-font-family);
  cursor: default;
  direction: ltr;
  /* webkit only issue #791 */
  z-index: 0;
  overflow: hidden;

  &--stacked {
    position: absolute;
    width: 100%;
    transform: translate3d(0, var(--y), 0) scale(var(--s));
    transition: transform 0.3s;

    &[data-collapsed] .#{$rt-namespace}__toast-body,
    &[data-collapsed] .#{$rt-namespace}__close-button {
      transition: opacity 0.1s;
    }

    &[data-collapsed="false"] {
      overflow: visible;
    }

    &[data-collapsed="true"]:not(:last-child) > * {
      opacity: 0;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      height: calc(var(--g) * 1px);
      bottom: 100%;
    }

    &[data-pos="top"] {
      top: 0;
    }

    &[data-pos="bot"] {
      bottom: 0;
    }

    &[data-pos="bot"]#{&}:before {
      transform-origin: top;
    }

    &[data-pos="top"]#{&}:before {
      transform-origin: bottom;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      transform: scaleY(3);
      z-index: -1;
    }
  }

  &-body {
    margin: auto 0;
    flex: 1 1 auto;
    padding: 16px !important;
    display: flex;
    align-items: center;
    & > div:last-child {
      word-break: break-word;
      flex: 1;
      font-size: 14px;
    }
  }
  &-icon {
    margin-inline-end: 12px !important;
    width: 20px;
    flex-shrink: 0;
    display: flex;
    color: #10b981 !important;
  }
  & > svg {
    fill: currentColor;
    height: 20px !important;
    width: 20px !important;
    margin-top: 13px;
    margin-right: 13px;
  }
}

.#{$rt-namespace}__close-button {
  color: #10b981 !important;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 1 !important;
  transition: 0.3s ease;
  align-self: flex-start;
  z-index: 1;
  &--light {
    color: #000;
    opacity: 0.3;
  }

  & > svg {
    fill: currentColor;
    height: 20px !important;
    width: 20px !important;
    margin-top: 14px;
    margin-right: 13px;
  }
}
:root {
  --toastify-color-light: #ecfdf5 !important;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #34d399 !important;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  // --toastify-icon-color-success: var(--toastify-color-lights);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-offset: 16px;
  --toastify-toast-top: max(
    var(--toastify-toast-offset),
    env(safe-area-inset-top)
  );
  --toastify-toast-right: max(
    var(--toastify-toast-offset),
    env(safe-area-inset-right)
  );
  --toastify-toast-left: max(
    var(--toastify-toast-offset),
    env(safe-area-inset-left)
  );
  --toastify-toast-bottom: max(
    var(--toastify-toast-offset),
    env(safe-area-inset-bottom)
  );
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-toast-bd-radius: 6px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  //Used only for colored theme
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  // Used when no type is provided
  --toastify-color-progress-light: linear-gradient(
    to right,
    #ffffff,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  // Used when no type is provided
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
  --toastify-color-progress-bgo: 0;
}
