@import "../../../Global_SCSS/base";

.admin-login-page {
  max-width: 100%;
  display: flex;
  // height: 100vh;
  justify-content: center;
  background-color: #f3f4f6;

}

.width-50 {
  max-width: 50%;
}
