@import "../../../Global_SCSS/base";
.user-order-page {
  display: flex;
  flex-direction: column;
  margin: 0px 3%;
  gap: 15px;
}
.button-header {
  background-color: #059669;
  color: white;
  font-size: 14px;
  line-height: 24px;
  padding: 0.5rem;
  border-radius: 6px;
  cursor: pointer;
  border: none;
}
.Add-new-hot-deal {
  display: flex;
  justify-content: space-between;
}

.custom-datatable tr:nth-child(even) {
  background-color: gray;
}
.rdt_TableRow {
  color: var(--Color-Gray-600, #4b5563);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
}
.rdt_TableRow:nth-child(even) {
  background: var(--Color-Gray-200, #e5e7eb);
}
.rdt_TableRow:nth-child(odd) {
  background: white;
}
.Header {
  display: flex;
  gap: 16px;
  padding-top: 2%;
  padding-left: 3%;
  align-items: center;
}

.table-container {
  margin: 2em 2%;
  box-shadow: 0px 1px 2px 0px #0000000f;
  box-shadow: 0px 1px 3px 0px #0000001a;
  background-color: #ffffff;
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  overflow-x: hidden !important;
}

.table-container table {
  border-spacing: 0;
  overflow-x: hidden;
  overflow-y: hidden;
}

.action-column-button {
  display: flex;
  justify-content: center;
  gap: 16px;
  cursor: pointer;
}
.custom-table {
  height: 100%;
}
.loading {
  padding-left: 10px;
}
.action-image {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 9px;
}



