@import "../../../Global_SCSS/base";
.footer-container {
  width: 100%;
  background-color: #ededed;
}
.footer-content {
  margin: 50px;
}
.logo-footer {
  display: flex;
  justify-content: space-between;
}
.header-logo2 {
  width: 20%;
  height: 3%;
}
.border-footer {
  margin-left: 50px;
  margin-right: 50px;
}

@media screen and (min-width: 360px) and (max-width: 540px) {
  .footer-content {
    margin: 20px;
  }
}
@media screen and (width: 768px) and (height: 1024px) {
  .footer-container {
    height: 45vh;
  }
}
@media screen and (width: 820px) and (height: 1180px) {
  .footer-container {
    height: 50vh;
  }
}
@media screen and (min-width: 1024px) and (min-height: 1366px) {
  .footer-container {
    height: 60vh;
  }
}
