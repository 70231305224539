@import "../../../Global_SCSS/base";
.review-container {
  padding-top: 3%;
  display: flex;

  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}
.order-review-text {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 15px;
}
.review-button {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 2%;
  margin-top: 3%;
  margin-bottom: 3%;
  padding: 2% 2% 0% 0;
  border-top: 1px solid $white--1;
}

.submit-button {
  background-color: #059669;
  color: white;
  font-size: 14px;
  line-height: 20px;
  padding: 0.56rem 1rem;
  border-radius: 6px;
  cursor: pointer;
  border: none;
}

@media screen and (min-width: 360px) and (max-width: 540px) {
  .review-button {
    margin-bottom: 30%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .review-container {
    gap: 0px;
  }
}
