@import "../../../Global_SCSS/base";

.comments-container {
  display: flex;
  gap: 0.5rem;
}
.comment-profile {
  height: 20px;
  width: 20px;
}
.comments-user-detail {
  display: flex;
  gap:0.2rem;
  font-size: 12px;
  color: #586474;
  line-height: 25px;
}
.comments-content {
  font-size: 14px;
  color: #212936;
  line-height: 22px;
}
