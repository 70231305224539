@import "../../../Global_SCSS//base";

// input[type="date" i] {
//   background-image: url("../../../Assets/arrow_down.svg");
//   font-size: 12px;
//   background-repeat: no-repeat;
//   background-position: right center; /* Adjust the position of the icon */
//   padding-right: 20px; /* Adjust the padding to make space for the icon */
// }
input[type="date"]::-webkit-calendar-picker-indicator {
  background-image: url("../../../Assets/Chevron_down.svg");
  background-position: right center;
  padding-right: 6px;
  width: 25px;
  height: 25px;
  // visibility: hidden;
}
