@import "../../../Global_SCSS/base";

.user-login-page {
  max-width: 100%;
  display: flex;
  height: 100vh;
}

.width-50 {
  max-width: 50%;
}

@media screen and (min-width: 360px) and (max-width: 540px) {
  .user-login-page {
    display: flex;
    flex-direction: column-reverse;
    height: 100vh;
  }
}

@media screen and (min-width: 280px) and (max-width: 350px) {
  .user-login-page {
    display: flex;
    flex-direction: column-reverse;
    height: 100vh;
  }
}
