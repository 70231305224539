.loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    height: 44vh;
    width: inherit;
}

.loading-spinner {
    border: 7px solid #F3F3F3;
    border-top: 7px solid #059669;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 1.5s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}