@import "../../../Global_SCSS/base";
.user-order-card-container {
  display: flex;
  flex-direction: row;
  height: 100px;
  width: max-content;
  gap: 22px;
}
.card-total {
  font-size: 43px;
  color: #212936;
  font-weight: bold;
}

.card-in-progress {
  font-size: 43px;
  color: #f2c94c;
  font-weight: bold;
}
.card-complete {
  font-size: 43px;
  color: #059669;
  font-weight: bold;
}

@media screen and (max-width: 430px) {
  .card-total {
    font-size: 24px;
  }
  .card-in-progress {
    font-size: 24px;
  }
  .card-complete {
    font-size: 24px;
  }
}
