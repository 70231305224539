@import "../../../Global_SCSS/base";

.user-login-form-parent {
  background-color: #ffffff;
  width: 50%;
}
.login-page {
  display: flex;
  flex-direction: column;
  background-color: #f3f4f6;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.login-page-client {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.login-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 32px;
  width: 464px;
}
.login-form-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 34px;
}

.user-login-form-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 34px;
}
.logo {
  width: 50%;
}
.login-text {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  color: #394150;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: #ffffff;
  border-radius: 6px;
  padding: 48px;
}

.all-rights-reserved {
  color: #6f6f6f;
  position: absolute;
  align-self: center;
  bottom: 15px;
}

.login-input-field {
  width: 97%;
  height: 38px;
  outline: none !important;
  border: solid 1px #d1d5db;
  padding-left: 6px;
  border-radius: 6px;
}
.form-button {
  display: flex;
  justify-content: space-between;
}
.remember-me {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.forgot-button {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #059669;
  cursor: pointer;
}
.signin-form-submit div {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #fff;
}

label {
  border: #455039;
}
.login-form-errors {
  padding: 32px 40px 32px 40px;
  width: 448px;
  height: auto;
  margin-top: 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 24px;
}
input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1px solid #d1d5db;
  background-color: #fff;
  position: relative;
}
input[type="checkbox"]:checked {
  background-color: #059669 !important;
  border: 1px solid #059669;
}
input[type="checkbox"]:checked::after {
  content: "\2713";
  display: block;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
}
.login-error-message {
  margin: 0;
  color: $red;
}
error-message {
  font-size: 12px;
}

.input-form-field,
.input-form-field-error {
  width: 100%;
  /*  height: 48px; */
  padding: 0;
  margin-bottom: 0;
  box-sizing: border-box;
}

.login-input-field {
  width: 100%;
  height: auto;
  outline: none !important;
  border: 1px solid #d1d5db;
  padding: 3%;
  border-radius: 6px;
}

.input-field-container input {
  box-sizing: border-box;
  padding-left: 10px;
}

@media only screen and (max-width: 1200px) {
  .login-form {
    padding: 6%;
  }
  .login-form-container {
    // margin-bottom: 210px;
    width: 80%;
  }
  .all-rights-reserved {
    bottom: 10px;
    // width: 80%;
    // display: flex;
    // justify-content: center;
  }
}

@media screen and (min-width: 360px) and (max-width: 540px) {
  .login-form-errors {
    width: 80%;
  }
  .form-button {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .user-login-form-parent {
    height: 50%;
    width: 100%;
  }
  .login-page-client {
    height: 100vh;
    gap: 175px;
  }
  .all-rights-reserved {
    position: relative;
    margin-top: 40px;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .login-form {
    gap: 13px;
  }
}
@media screen and (min-width: 280px) and (max-width: 350px) {
  .login-form-errors {
    width: 70%;
  }
  .form-button {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .user-login-form-parent {
    height: 50%;
    width: 100%;
  }
  .login-page-client {
    height: 100vh;
    gap: 140px;
  }
  .all-rights-reserved {
    position: relative;
    margin-top: 40px;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .login-form {
    gap: 13px;
  }
}
@media screen and (min-width: 540px) and (max-width: 768px) {
  .form-button {
    display: flex;
    flex-direction: column;
    gap: 18px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .login-page {
    justify-content: flex-start;
    gap: 60px;
  }
  .login-form {
    gap: px;
  }
  .user-login-form-parent {
    margin-top: 100px;
  }
  .login-form-errors {
    width: 70%;
  }
  .login-page-client {
    justify-content: start;
    gap: 150px;
    padding-top: 50px;
    align-items: center;
  }
  .all-rights-reserved {
    position: relative;
  }
}
@media screen and (width: 1024px) and (height: 1366px) {
  .login-page-client {
    height: 80vh;
  }
}
