@import "../../../Global_SCSS/base";

.accordion-data-container {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 24px;
}
.accordion-data-inner-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.accordion-data-title {
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  color: #6b7280;
}
.accordion-data {
  font-size: 14px;
  color: #1f2937;
  font-family: Inter;
  font-weight: 500;
}
