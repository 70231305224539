body {
  font-family: Inter;
}

.width-50 {
  width: 50%;
}

#payment-form {
  max-width: 45%;
  margin-top: 50px;
  margin-bottom: 300px;
}

#payment-form #submit {
  background-color: #059669;
  padding: 13px 25px 13px 25px;
  border-radius: 6px;
  color: #ffffff;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  margin-top: 30px;
  margin-right: 10px;
  height: 38px !important;
  font-family: Inter;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
}

#payment-form #submit:disabled {
  background-color: #c5c7cc;
}

#payment-form #payment-message {
  color: red;
  margin-top: 5%;
}

@media screen and (max-width: 430px) {
 
#payment-form {
  max-width: 85%;
  margin-top: 50px;
  margin-bottom: 300px;
}

}