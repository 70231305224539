.setting-layout {
  width: 60%;
  background-color: #fff;
  display: flex;
  gap: 10px;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-left: 3%;
  min-height: 50vh;
}

.setting-left-bar {
  width: 30%;
  border-right: 1px solid #e5e7eb;
}

.setting-left-bar-child {
  // width: 70%;
  text-align: left;
}

.setting-left-bar-items {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  text-transform: capitalize;
  padding: 0.8rem 0rem;
  border-radius: 6px;
  cursor: pointer;
}

.setting-left-bar-items.active {
  // background-color: #f3f4f6;
  color: #059669;
  font-weight: 600;
}

.setting-left-bar-items.option {
  color: #4d5562;
  font-weight: 500;
}

.setting-main-content {
  width: 80%;
  padding: 0 3%;
}

@media only screen and (max-width: 1025px) {
  .setting-layout {
    width: 75%;
  }

  .setting-left-bar-items {
    text-transform: capitalize;
    padding: 0.6rem 1rem;
    border-radius: 6px;
    cursor: pointer;
  }

  .setting-left-bar-child {
    width: 80%;
    text-align: left;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .setting-layout {
    width: 85%;
    gap: 0px;
    margin-left: 4%;
  }

  .setting-left-bar {
    width: 35%;
  }

  .setting-left-bar-items {
    padding: 0.5rem 0.6rem;
  }

  .setting-left-bar-child {
    width: 80%;
    text-align: left;
  }
}

@media screen and (min-width: 360px) and (max-width: 540px) {
  .setting-main-content {
    width: 64%;
    padding: 2% 3%;
  }
  .setting-layout {
    width: 90%;
    gap: 0px;
    padding: 0px;
  }
  .setting-left-icon {
    width: 15px;
    height: 15px;
  }

  .setting-left-bar-items.active {
    font-size: 13px;
  }

  .setting-left-bar-items.option {
    font-size: 13px;
  }
  .setting-left-bar {
    padding-top: 35px;
    padding-left: 5px;
  }
  .setting-left-bar-items {
    gap: 0.25rem;
  }
}
