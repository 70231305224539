.input-field-container {
  position: relative;
}
.input-field {
  border: none;
  width: 100%;
  height: 90%;
  outline: none !important;
}
.input-form-field-error {
  outline: none !important;
  border-radius: 6px;
  border: 3px solid #c52a1a;
  background-color: rgba(255, 255, 255, 0.9);
}

.eye-icon {
  position: absolute;
  padding-right: 10px;
  right: 10px;
  top: 50%;
  color: #9ca3af;
  transform: translateY(-50%);
  cursor: pointer;
}
.error-message {
  outline: none !important;
  color: #c52a1a;
  margin: 0px;
}
