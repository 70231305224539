@import "../../../Global_SCSS/base";

.user-order-card {
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.0588235294);
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 4px;
  padding-left: 3%;
  padding-top: 4%;
  padding-bottom: 3%;
  width: 150px;
}
.user-order-card-header {
  display: flex;
  gap: 6px;
}

@media screen and (min-width: 360px) and (max-width: 430px) {
  .user-order-card {
    border-radius: 4px;
    padding-left: 1.5%;
    padding-top: 2%;
    width: 80%;
  }
}
