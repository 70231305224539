// .hot-deals {
//   width: 100% !important;
// }
@import "../../../Global_SCSS/base";
.deals_details {
  padding: 2% 3.4%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $gray-3;
  height: 61px;
}
li {
  list-style: none;
}
.deal-details-text {
  color: $black--0;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

@media screen and (max-width: 430px) {
  .deal-details-text {
    font-size: 10px;
    line-height: 12px;
  }
}
