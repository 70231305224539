@import "../../../Global_SCSS/base";
.card-header {
  display: flex;
  gap: 6px;
}
.card-header-text {
  color: #212936;
  font-size: 13.5px;
  font-weight: 600;
}

@media screen and (min-width: 360px) and (max-width: 430px) {
  .card-header-text {
    font-size: 12px;
  }
}
