@import "../../../Global_SCSS/base";
.delete-message-container {
  display: flex;
  background-color: #4b5563;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
}

.delete-message-box {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 512px;
  background-color: white;
  border-radius: 8px;
}

.delete-message-header {
  display: flex;
  flex-direction: row;
  padding: 0.5em;
  margin: 1em;
  gap: 16px;
}

.delete-message-logo {
  width: 40px;
  height: 40px;
}

.delete-message-header-text {
  flex-direction: column;
  gap: 20px;
}

.delete-message-text {
  color: #111827;
  font-size: 18px;
  line-height: 24px;
}

.delete-confirmation-text {
  margin-top: 0.5em;
  color: #6b7280;
}

.delete-message-footer {
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  padding: 0.5em;
}

.delete-cancel-button {
  background-color: white;
  color: #374151;
  font-size: 16px;
  width: 81px;
  height: 38px;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  cursor: pointer;
}

.delete-button {
  background-color: #059669;
  color: white;
  font-size: 16px;
  padding: 0.56rem 1rem;
  margin-right: 1em;
  border-radius: 6px;
  cursor: pointer;
  border: none;
}
@media screen and (max-width: 430px) {
  .delete-message-box {
   
    width: 75%;
   
  }
}
