@import "../../../Global_SCSS/base";

.content-bar {
  display: flex;
  width: 100%;
  height: 140px;
  justify-content: space-between;
}

@media screen and (min-width: 360px) and (max-width: 540px) {
  .content-bar {
    flex-direction: column;

    gap: 0.5rem;
    height: 50vh;
  }
}

@media screen and (min-width: 280px) and (max-width: 350px) {
  .content-bar {
    flex-direction: column;

    gap: 0.5rem;
    height: 68vh;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .content-bar {

    gap: 0.5rem;
    height: 25vh;
  }
}
@media screen and (min-width: 1920px) {
  .content-bar {
    width: 100%;
   
  }
}
