@import "../../Global_SCSS/base";

.hamburger-main-container {
  display: none;
}

@media screen and (min-width: 360px) and (max-width: 540px) {
  .hamburger-main-container {
    display: flex;
  
  }
  
}

@media screen and (min-width: 280px) and (max-width: 350px) {
  .hamburger-main-container {
    display: flex;
  
  }
}