.display-screen {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 290px;
  height: 100vh;
  overflow-y: auto;
  background-color: #f3f4f6;
}

* {
  margin: 0;
}

.Sidebar {
  width: 100%;
  max-width: 300px;
  background-color: #ffffff;
  position: -webkit-sticky;
  position: fixed;
  height: 100vh;
  border-right: 1px solid #e5e7eb;
}
.sidebar-medium {
  // display: flex;
  width: 100%;
  max-width: 200px;
  background-color: #ffffff;
  position: -webkit-sticky;
  z-index: 9999;
  position: fixed;
  height: 100vh;
  border-right: 1px solid #e5e7eb;
}

.layout {
  display: flex;
  overflow: hidden;
  height: max-content;
}

.content {
  background-color: #f3f4f6;
}
@media screen and (min-width: 360px) and (max-width: 540px) {
  .display-screen {
    margin-left: 0px;
  }
  .Sidebar {
    display: none;
  }
  .content {
    margin-top: 13px;
  }
  .hamburger-container
  {
    position: relative;
  }
}

@media screen and (min-width: 280px) and (max-width: 350px) {
  .display-screen {
    margin-left: 0px;
  }
  .Sidebar {
    display: none;
  }
  .content {
    margin-top: 13px;
  }
  .hamburger-container
  {
    position: relative;
  }
}
