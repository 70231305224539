@import "../../../Global_SCSS/base";
.hero-container {
    display: none;
  }

@media screen and (min-width: 360px) and (max-width: 540px) {
  .hero-container {
    display: flex;
    padding: 16px;
    justify-content: space-between;
  }
}

@media screen and (min-width: 280px) and (max-width: 350px) {
  .hero-container {
    display: flex;
    padding: 16px;
    justify-content: space-between;
  }
}

