@import "../../../Global_SCSS/base";

.button-container {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}

.header-login-button {
  height: 3.6rem;
  width: 8rem;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  padding: 1rem 2rem;
  background-color: #556c85;
}

.header-signup-button {
  height: 3.6rem;
  width: 8rem;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  padding: 1rem 2rem;
  background-color: #5c9452;
}

@media screen and (min-width: 360px) and (max-width: 540px) {
  .button-container {
    color: #ffffff;
    border: none;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .header-login-button {
    height: 1.8rem;
    width: 8rem;

    font-weight: 300;
    font-size: 16px;
    line-height: 16px;
    border-radius: 5px;
    background-color: #556c85;
  }

  .header-signup-button {
    height: 1rem;
    width: 9rem;

    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    border-radius: 5px;
    background-color: #5c9452;
  }
}

@media screen and (min-width: 280px) and (max-width: 350px)
{
  .button-container {
    color: #ffffff;
    border: none;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .header-login-button {
    height: 1.8rem;
    width: 8rem;

    font-weight: 300;
    font-size: 16px;
    line-height: 16px;
    border-radius: 5px;
    background-color: #556c85;
  }

  .header-signup-button {
    height: 1rem;
    width: 9rem;

    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    border-radius: 5px;
    background-color: #5c9452;
  }
}
@media screen and (min-width: 540px) {
 
  .header-login-button {
    height: 0.6rem;
    width: 7rem;

    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    border-radius: 5px;
    background-color: #556c85;
  }

  .header-signup-button {
    height: 0.6rem;
    width: 7rem;

    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    border-radius: 5px;
    background-color: #5c9452;
  }
}