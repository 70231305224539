@import "../../../Global_SCSS/base";

.order-details-container {
  margin: 0px 3%;

  display: flex;
  flex-direction: column;
  padding-left: 37px;
  padding-right: 37px;
  padding-top: 24px;

  background-color: #ffffff;
  border-radius: 8px;
}
.order-details-header {
  color: #111827;
  display: flex;
  justify-content: space-between;
  height: 57px;
  font-size: 24px;
  font-weight: 600;
  border-bottom: 1px solid #dedede;
}
.order-status-container {
  display: flex;
  height: 24px;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  background-color: #fef3c7;
  padding: 2px 15px;
}
.order-in-progress {
  color: #92400e;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
}

.status-dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #fbbf24;
}

.download-button {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 20px;
}

@media screen and (min-width: 360px) and (max-width: 540px) {
  .order-details-container {
 
    padding-top: 13px;
  
  }
}
