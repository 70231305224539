@import "../../../Global_SCSS/base";

.status-tag {
  padding: 8px;
  text-align: center;
  display: inline-flex;
  gap: 0.5rem;
  text-transform: capitalize;
  border-radius: 4px;
  background-color: #fef3c7;
  color: #92400e;
  position: relative;
}

.status-unpaid,
.status-invited,
.status-neworder,
.status-inprogress {
  padding: 8px;
  text-align: center;
  display: inline-flex;
  gap: 0.5rem;
  text-transform: capitalize;
  border-radius: 4px;
  position: relative;
  background-color: #fef3c7;
  color: #92400e;
}

.status-paid,
.status-accepted,
.status-signedup,
.status-completed {
  padding: 8px;
  text-align: center;
  display: inline-flex;
  gap: 0.5rem;
  text-transform: capitalize;
  border-radius: 4px;
  position: relative;
  background-color: #d1fae5;
  color: #065f46;
}

.status-revise {
  background-color: #fee2e2;
  color: #991b1b;
}

.status-default {
  background-color: 2px solid #065f46;
  color: #065f46;
}

@media screen and (min-width: 360px) and (max-width: 430px) {
  .status-tag {
    padding: 4px;
    font-size: 12px;
  }
  .status-unpaid,
  .status-paid {
    padding: 4px;
    font-size: 9px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .status-tag {
    padding: 4px;
    font-size: 9px;
  }
  .status-unpaid,
  .status-paid {
    padding: 4px;
    font-size: 9px;
  }
}
@media screen and (min-width: 280px) and (max-width: 350px) {
  .status-tag {
    padding: 4px;
    font-size: 9px;
  }
  .status-unpaid,
  .status-paid {
    padding: 4px;
    font-size: 9px;
  }
}
