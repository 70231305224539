@import "../../../Global_SCSS/base";
.header-styled-text-container {
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  gap: 15px;
}
.grey-styled-text {
  font-size: 64px;
  line-height: 72px;
  font-weight: 400;
  color: #556c85;
  font-family: "Playfair Display";
}
.green-italic-text {
  font-size: 72px;
  line-height: 72px;
  font-weight: 400;
  color: #5c9452;
  font-family: "Playfair Display";
  font-style: italic;
}
.solid-black-text {
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  color: #000000;
}
.get-started-button {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  padding: 1.3rem;
  border-radius: 5px;
  color: #ffffff;
  background-color: #5c9452;
  cursor: pointer;
}

@media screen and (max-width: 359px) {
}

@media screen and (min-width: 360px) and (max-width: 540px) {
  .header-styled-text-container {
    gap: 14px;
    margin-top: 1rem;
  }
  .grey-styled-text {
    font-size: 40px;
    line-height: 40px;
  }
  .green-italic-text {
    font-size: 40px;
    line-height: 40px;
  }
  .solid-black-text {
    font-size: 16px;
    line-height: 24px;
    font-weight: 200;
    color: #000000;
  }

}

@media screen and (min-width: 280px) and (max-width: 360px) {
  .header-styled-text-container {
    gap: 14px;
    margin-top: 0.25rem;
  }
  .grey-styled-text {
    font-size: 40px;
    line-height: 40px;
  }
  .green-italic-text {
    font-size: 40px;
    line-height: 40px;
  }
  .solid-black-text {
    font-size: 16px;
    line-height: 24px;
    font-weight: 200;
    color: #000000;
  }

}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .grey-styled-text {
    font-size: 44px;
    line-height: 47px;
  }
  .green-italic-text {
    font-size: 44px;
    line-height: 47px;
  }
  .solid-black-text {
    font-size: 16px;
    line-height: 26px;
  }
}

@media screen and (min-width: 1024px) {

  .grey-styled-text {
    font-size: 41px;
    line-height: 46px;
  }
  .green-italic-text {
    font-size: 36px;
    line-height: 40px;
    font-weight: 400;
  }
  .solid-black-text {
    font-size: 16px;
  }
}

@media screen and (min-width: 1440px) {
  .grey-styled-text {
    font-size: 50px;
    line-height: 60px;
    font-weight: 400;
  }
}
