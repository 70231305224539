.create-primary-button {
  background-color: #059669;

  border-radius: 6px;
  color: #ffffff;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  font-family: Inter;
  font-weight: 500;
  cursor: pointer;

  font-size: 14px;
  line-height: 24px;
  padding: 0.5rem 1rem;
}
.create-primary-button:disabled {
  background-color: #cbcbcb;
  cursor: default;
}

.font-14 {
  font-size: 14px;
  line-height: 25px;
}

.font-12 {
  font-size: 12px;
  line-height: 16px;
}

.create-primary-button-white {
  padding: 8px 18px;
  border-radius: 6px;
  background-color: #ffffff;
  color: #374151;
  border: 1px solid #d1d5db;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  box-shadow: 0px 1px 2px 0px #0000000d;
  font-family: Inter;
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  cursor: pointer;
}

.create-primary-button-white:disabled {
  cursor: default;
  border: none;
  color: #fff;
  background-color: #cbcbcb;
}

@media screen and (min-width: 360px) and (max-width: 540px) {
  .create-primary-button {
    border-radius: 4px;

    font-family: Inter;
    font-weight: 500;

    font-size: 12px;
    line-height: 16px;
    padding: 1rem 1rem;
  }
}

@media screen and (min-width: 280px) and (max-width: 350px) {
  .create-primary-button {
    border-radius: 4px;

    font-family: Inter;
    font-weight: 500;

    font-size: 9px;
    line-height: 12px;
    padding: 1rem 1rem;
  }
}

