.create-new-hot-deal-message-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-self: center;
  justify-content: center;
  align-items: center;
  margin-top: 4.7%;
  padding-bottom: 4%;
  gap: 0.5rem;
}
.text-center {
  text-align: center;
}
.no-deals {
  color: #6b7280;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
}

.not-found-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.hotdeal-tag-logo {
  width: 48px;
  height: 48px;
}
.bottom-create-button {
  background-color: #059669;
  color: white;
  font-size: 14px;
  line-height: 24px;
  padding: 0.8rem 2rem;
  border-radius: 6px;
  cursor: pointer;
  border: none;
}
