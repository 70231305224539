@import "../../../Global_SCSS/base";

.payment-method-container {
  display: flex;
  flex-direction: column;
  padding-right: 44px;
  gap: 24px;
}

.payment-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.payment-options-container {
  display: flex;
  height: 65px;
  margin-right: 7px;
  gap: 8px;
  // background-color: aquamarine;
}

.payment-input-field {
  width: 100%;
  height: 38px;
  outline: none !important;
  border: solid 1px #d1d5db;
  padding-left: 6px;
  border-radius: 6px;
  color: #374151;
}

.payment-from-footer {
  display: flex;
  width: 399px;
  gap: 9px;
  padding-bottom: 3%;
}

.width_100 {
  width: 100%;
}

.payment-method-button {
  display: flex;
  justify-content: flex-start;
  gap: 2%;
  margin-top: 3%;
  margin-bottom: 3%;
  padding: 2% 0% 0% 0%;
  cursor: pointer;
}

.price-tag {
  background-color: #f3f4f6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  margin-top: 4%;
  border-radius: 8px;
  padding: 2.1rem 1.1rem;
}
.price-tag-text {
  color: #4d5562;
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
}

.price-tag-green-text {
  color: #059669;
  font-size: 34px;
  font-weight: 600;
  font-family: Inter;
}

.card-input-field {
  outline: none;
}
.custom-card-input-field {
  width: 100%;
  height: 38px;
  outline: none !important;
  border: solid 1px #D1D5DB;
  padding-top: 15px;
  border-radius: 6px;
  padding-left: 5px;;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

