.saved-payment-inner-container {
  background-color: #ffffff;
  padding: 1.5rem 2.8rem 1.5rem 1.5rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin-bottom: 20px;
}
.clickable-container {
  cursor: pointer;
}
.faded-cards-container {
  filter: grayscale(50%);
  pointer-events: none;
}
.saved-payment-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.payment-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.payment-info-text {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
}
.regular-black-text {
  color: black;
  font-size: 14px;
  line-height: 20px;
}
.card-icon {
  width: 30px;
  height: 30px;
}
.card-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

@media screen and (max-width: 430px) {
  .saved-payment-inner-container {
    padding: 0.5rem;
  }
  .card-icon {
    width: 18px;
    height: 18px;
  }
  .regular-black-text {
    font-size: 11px;
    line-height: 12px;
  }
}
