@import "../../../Global_SCSS/base";
.user-management-screen {
  margin: 0px 3%;
}

.main-content {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.0588235294);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1019607843);
  background-color: #ffffff;
  border-radius: 8px;
  // height: 83vh;
  margin-top: 0;
}

.button-header {
  // margin-top: 3%;
  // margin-right: 2.5%;
}
.search-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  margin: 0rem 2.5rem 0 35px;
}

.Add-new-hot-deal {
  display: flex;
  justify-content: space-between;
}

.rdt_TableCol:first-child {
  padding-left: 35px;
  padding-right: 0;
}

.custom-datatable tr:nth-child(even) {
  background-color: gray;
}

.rdt_TableRow {
  color: var(--Color-Gray-600, #4b5563);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
}
.rdt_TableRow:nth-child(even) {
  background: var(--Color-Gray-200, #e5e7eb);
}
.rdt_TableRow:nth-child(odd) {
  background: white;
}
.Header {
  display: flex;
  gap: 16px;
  padding-top: 2%;
  padding-left: 3%;
  align-items: center;
}

.table-container {
  margin: 2em 2%;
  box-shadow: 0px 1px 2px 0px #0000000f;
  box-shadow: 0px 1px 3px 0px #0000001a;
  background-color: #ffffff;
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  overflow-x: hidden !important;
}

.table-container table {
  border-spacing: 0;
  overflow-x: hidden;
  overflow-y: hidden;
}

.action-column-button {
  display: flex;
  justify-content: center;
  gap: 16px;
  cursor: pointer;
}
.payment-method-cell {
  text-transform: capitalize;
}

@media screen and (min-width: 360px) and (max-width: 540px) {
  .main-content {
    padding: 6px;
  }
  .search-content {
    flex-flow: nowrap;
    flex-direction: column;
    gap: 12px;
    align-items: self-start;
    max-width: 100%;
    margin: 0rem;
  }
}

@media screen and (min-width: 280px) and (max-width: 350px) {
  .main-content {
    padding: 6px;
  }
  .search-content {
    flex-flow: nowrap;
    flex-direction: column;
    gap: 12px;
    align-items: self-start;
    max-width: 100%;
    margin: 0rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .search-content {
    flex-flow: wrap;
    gap: 10px;
    margin: 1rem;
    align-items: center;
    max-width: 100%;
  }
}
