.label {
  font-size: 14px;
  color: #374151;
  line-height: 20px;
  font-weight:500;
}

.input-field-container {
  position: relative;
}
.input-field {
  border: none;
  width: 100%;
  height: 90%;
  outline: none !important;
}
.input-form-field-error {
  outline: none !important;
  border-radius: 6px;
  border: 3px solid #c52a1a;
  background-color: rgba(255, 255, 255, 0.9);
}

.error-message {
  outline: none !important;
  color: #c52a1a;
  margin: 0px;
}
.required::after {
  content: " *";
  color: red;
  font-size: 20px;
}