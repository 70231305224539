@import "../../../Global_SCSS/base";
.header-container {
  display: flex;
  position: absolute;
  padding-top: 1.75rem;
  width: 81%;
  justify-content: space-between;
}

@media screen and (min-width: 360px) and (max-width: 540px) {
  .header-container {
    display: none;
  }
}

@media screen and (min-width: 280px) and (max-width: 350px) {
  .header-container {
    display: none;
  }
}

