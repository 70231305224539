@import "../../../Global_SCSS/base";

.content-sample-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 76px 153px;
  background-color: #ededed;
}
.content-sample-inner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 81%;
  padding: 3.4rem 2.5rem 2.6rem 2.5rem;
  gap: 2rem;
  align-items: center;
  background-color: #f9f9fb;
  border-radius: 20px;
}
.content-sample-bg-container {
  margin-top: 20px;
  width: 100%;
}

.small-green-roboto {
  font-size: 16px;
  line-height: 24px;
  color: #5c9452;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
}
.large-black-playfair {
  font-size: 96px;
  line-height: 70px;
  font-weight: 500;
  font-family: "Playfair Display";
}

@media screen and (min-width: 360px) and (max-width: 540px) {
  .content-sample-container {
    padding: 64px 35px;
  }
  .content-sample-inner-container {
    padding-top: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1.5rem;
    border-radius: 7px;
  }
  .large-black-playfair {
    font-size: 31px;
    line-height: 50px;
    font-weight: 500;
    font-family: "Playfair Display";
  }
}
@media screen and (min-width: 280px) and (max-width: 350px) {
  .content-sample-container {
    padding: 64px 35px;
  }
  .content-sample-inner-container {
    padding-top: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1.5rem;
    border-radius: 7px;
  }
  .large-black-playfair {
    font-size: 31px;
    line-height: 50px;
    font-weight: 500;
    font-family: "Playfair Display";
  }
}


@media screen and (min-width: 1024px) {
  .large-black-playfair {
    font-size: 76px;
    line-height: 80px;
  }
  .content-sample-inner-container {
    width: 100%;
  }
}

@media screen and (min-width: 1920px) {
  .content-sample-inner-container {
    width: 75%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .content-sample-container {
    padding: 50px 70px;
  }
  .large-black-playfair {
    font-size: 66px;
    line-height: 72px;
  }
}
