@import "../../../Global_SCSS/base";

.bottom-bg-container {
  background-image: url("../../../Assets/LandingPageAssets/footer-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.bottom-gradient {
  top: 0;
  left: 0;
  padding-top: 150px;
  padding-bottom: 150px;
  padding-left: 157px;
  background: linear-gradient(
    to right,
    #000000 20%,
    rgba(255, 255, 255, 0) 100%
  );
}
.bottom-bg-container-sm {
  display: none;
}

.bottom-content-container {
  display: flex;
  flex-direction: column;
}
.bottom-content-container-sm {
  display: none;
}

@media screen and (min-width: 360px) and (max-width: 540px) {
  .bottom-bg-container {
    display: none;
  }
  .bottom-bg-container-sm {
    display: flex;
    width: 100%;
  }
  .bottom-content-container {
    display: none;
  }
  .bottom-content-container-sm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 30px 0px 30px;
    background-color: #0f1921;
  }
  .bottom-gradient {
    display: none;
  }
}

@media screen and (min-width: 280px) and (max-width: 350px) {
  .bottom-bg-container {
    display: none;
  }
  .bottom-bg-container-sm {
    display: flex;
    width: 100%;
  }
  .bottom-content-container {
    display: none;
  }
  .bottom-content-container-sm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 30px 0px 30px;
    background-color: #0f1921;
  }
  .bottom-gradient {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .bottom-bg-container {
    padding: 0px;
  }
  .bottom-gradient {
    padding: 50px;

    background: linear-gradient(
      to right,
      #000000 20%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}
