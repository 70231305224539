.active-select {
  border: 1px solid #059669;
  border-radius: 0px 6px 6px 0px;
}

.Filter_img {
  width: 20px;
  height: 20px;
}
// .selected-option:active {
//   border: 1px solid #059669;
// }
.selected-option:hover {
  cursor: pointer;
  border: 1px solid #059669;
}

.selected-option {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0px 6px 6px 0px;
  background: var(--Color-Gray-50, #f9fafb);
  color: #6c727f;
  // width: 93px;
  padding: 10px;
  height: max-content;
  border: 1px solid #d1d5db;
}

.selected-option.selected {
  cursor: pointer;
  border: 4px solid #059669; /* Persistent border color for selected state */
}
.selected-focused {
  cursor: pointer;
  border: 1px solid #059669;
}

.custom-options {
  display: flex;
  width: 212px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 6px;
  background: var(--white, #fff);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  height: auto;
  background: var(--white, #fff);
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
}
.options {
  display: flex;
  justify-content: flex-start;
  padding-bottom: 10px;
  // padding-top: 10px;
}
.options-value {
  padding-top: 10px;
  padding-left: 10px;
}

.active-option {
  display: flex;
  gap: 12px;
  align-self: stretch;
  color: var(--gray-700, #374151);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-top: 6px;
  padding: 8px 16px 8px 16px;
}

// new css
.options-container {
  position: absolute;
  z-index: 999;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 3px 8px 0px #342e2e0d;
  border: 1px solid #ececec;
  padding: 10px;
  // min-width: 20%;
}

.options-container ul > li {
  cursor: pointer;
}

.checkbox-category {
  padding: 10px;
}

.checkbox-option {
  display: flex;
  gap: 10px;
  padding: 5px 0;
  align-items: center;
}

.checkbox-option label {
  margin-bottom: 0;
}

@media screen and (max-width: 430px) {
  .options-container {
    padding: 0px;
  }
}
