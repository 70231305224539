.toggle-button {
  margin-top: 1.5rem;
  margin-left: 1rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 34px;
  height: 21px;
  background: none;
  border: none;
}

.toggle-button .bar {
  height: 3px;
  width: 100%;
  background-color: #4d5562;
  border-radius: 10px;
}
.hamburger-containers {
  display: none;
}

@media screen and (min-width: 360px) and (max-width: 540px) {
  .toggle-button {
    display: flex;
  }
  .hamburger-containers {
    display: flex;
  }
}

@media screen and (min-width: 280px) and (max-width: 350px) {
  .toggle-button {
    display: flex;
  }
  .hamburger-containers {
    display: flex;
  }
}
