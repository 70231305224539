$green: #059669;
$gray-00: #f3f4f6;
$gray: #6c727f;
$gray-1: #212936;
$gray--2: #f9fafb;
$gray-3: #f9fafb;
$black--0: #1f2937;
$white--1: #d1d5db;
$white-00: #ffffff;
$black--1: #374151;
$red: #ff0000;
$black-3: #111827;
