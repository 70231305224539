@import "../../../Global_SCSS/base";

.upload-file-main-container {
  margin-bottom: 5%;
}

.upload-file-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 36%;
}

.upload-file-inner {
  display: flex;
  border: 2px dashed #d1d5db;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
}

.upload-file-content {
  display: flex;
  flex-direction: column;
  padding: 2.2rem 0rem;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
}

.upload-file-icon {
  color: #9ca3af;
  //   width: 36px;
  //   height: 36px;
}

.upload-file-type {
  font-size: 12px;
  font-family: "Inter";
  color: #6b7280;
}

.upload-file-box {
  display: flex;
  gap: 0.25rem;
}

.upload-head-text {
  font-family: "Inter";
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #111827;
  margin-bottom: 3%;
}

.upload-text {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  color: #059669;
  cursor: pointer;
}

.drag-and-drop-text {
  font-size: 14px;
  color: #4b5563;
}

.uploaded-file-info {
  display: flex;
  width: 40%;
  // justify-content: space-between;
  gap: 3rem;
}

.uploaded-file-name-container {
  display: flex;
  gap: 0.4rem;
}

.previous-file-container {
  width: 40%;
  margin: 2% 0 0 0;
}

.previous-file-inner-container {
  display: flex;
  width: 100%;
  // justify-content: space-between;
  gap: 3rem;
}

.cursor-pointer {
  cursor: pointer;
}

@media screen and (min-width: 280px) and (max-width: 350px) {
  .upload-text {
    font-size: 11px;
  }

  .upload-file-container {
    width: 100%;
  }
}

@media screen and (min-width: 360px) and (max-width: 540px) {
  .upload-text {
    font-size: 11px;
  }

  .upload-file-container {
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .upload-text {
    font-size: 11px;
  }

  .upload-file-container {
    width: 100%;
  }
}
