@import "../../../Global_SCSS/base";

.landing-page-main-container {
  display: flex;
  flex-direction: column;
}

.header-bg-container {
  position: relative;

  height: 100vh;
}

.landing-page-header-bg {
  background-image: url("../../../Assets/LandingPageAssets/header-bg.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
}

.landing-page-header-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, white 20%, rgba(255, 255, 255, 0) 55%);
}

.header-text-container {
  margin-left: 10%;
  margin-top: 11rem;
  width: 39%;
  position: absolute;
  display: flex;
}

.logo-bar-parent-container {
  justify-items: flex-end;
}

@media screen and (min-width: 360px) and (max-width: 540px) {
  .header-bg-container {
    position: relative;
    height: 44vh;
    padding-left: 16px;
    padding-right: 16px;
  }
  .landing-page-header-bg {
    background-image: url("../../../Assets/LandingPageAssets/header-bg-sm.png");
    position: static;
  }
  .landing-page-header-bg::before {
    display: none;
  }

  .header-text-container {
    position: static;
    align-items: center;
    margin-left: 0%;
    margin-top: 0rem;
    padding: 0% 5%;
    width: 90%;
  }
}
@media screen and (min-width: 280px) and (max-width: 350px) {
  .header-bg-container {
    position: relative;
    height: 32vh;
    padding-left: 16px;
    padding-right: 16px;
  }
  .landing-page-header-bg {
    background-image: url("../../../Assets/LandingPageAssets/header-bg-sm.png");
    position: static;
  }
  .landing-page-header-bg::before {
    display: none;
  }

  .header-text-container {
    position: static;
    align-items: center;
    margin-left: 0%;
    margin-top: 0rem;
    padding: 0% 5%;
    width: 90%;
  }
}
@media only screen and (min-width: 431px) and (max-width: 540px) {
  .header-text-container {
    width: 65%;
  }
}
@media only screen and (min-width: 540px) and (max-width: 767px) {
  .header-bg-container {
    height: 50vh;
  }
}
@media only screen and (width: 768px) and (height: 1024px) {
  .header-text-container {
    margin-top: 4rem;
    width: 70%;
  }
  .header-bg-container {
    height: 60vh;
  }
}

@media only screen and (width: 820px) and (height: 1180px) {
  .header-bg-container {
    height: 60vh;
  }
  .header-text-container {
    width: 60%;
  }
}

@media only screen and (width: 1024px) and (height: 1366px) {
  .header-bg-container {
    height: 60vh;
  }
  .header-text-container {
    margin-top: 9rem;
    width: 51%;
  }
}
