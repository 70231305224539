.breadcrumbs {
  height: 8vh;
  display: flex;
  justify-content: space-between;
  background-color: #f3f4f6;
}
.breadcrums-link {
  display: flex;
  gap: 16px;
  align-items: center;
  padding-top: 2%;
  padding-left: 3%;
  padding-bottom: 2%;
}

.link-text {
  white-space: nowrap;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #6b7280;
}
.user-profile {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 34px;
}
.profiledetail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.profile-name {
  white-space: nowrap;
  color: var(--Color-Grey-900, #121826);
  text-transform: capitalize;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.logout {
  color: var(--Color-Grey-500, #6c727f);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 208.333% */
  letter-spacing: 0.35px;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
}
.profile-picture {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.profile-pic {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #4d5562;
  text-transform: capitalize;
}

@media screen and (min-width: 360px) and (max-width: 540px) {
  .breadcrumbs {
    justify-content: space-between;
  }

  .breadcrums-link {
    // gap: 6px;
    display: none;
  }
  .link-text {
    font-size: 10px;
    color: #6b7280;
  }
}

@media screen and (min-width: 280px) and (max-width: 350px) {
  .breadcrumbs {
    justify-content: space-between;
  }

  .breadcrums-link {
    // gap: 6px;
    display: none;
  }
  .link-text {
    font-size: 10px;
    color: #6b7280;
  }
}
