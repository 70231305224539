@import "../../../Global_SCSS/base";

a {
  text-decoration: none;
}

.sidebar-subcontainer {
  position: fixed;
  height: 100%;
  width: inherit;
}

.LogoImg {
  width: 136px;
  padding-bottom: 2%;
  margin-top: 20.39px;
  margin-left: 5.22px;
}

.Navbar {
  display: flex;
  flex-direction: column;
  padding-left: 0;
}

.SidebarItem {
  display: flex;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  padding-left: 12px;
  transition: 0.3s;
  border-left: 4px solid transparent
}

.SidebarItem.active {
  color: $green;
  background-color: $gray-00;
  border-left: 4px solid $green;
}

.ActiveLink {
  color: $gray;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
}

.SideBarIcon {
  width: 24px;
  height: 24px;
}

.SidebarItem .SideBarIcon {
  transition: fill 0.3s;
}

.ActiveText {
  color: $green;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
}

@media screen and (min-width: 1024px) {
  .LogoImg {
    width: 136px;
    padding-bottom: 20px;
    margin-top: 20.39px;
    margin-left: 15px;
  }

  .ActiveLink {
    // font-size: 20px;
    white-space: nowrap;
  }

  .SidebarItem {
    height: 50px;
  }
}