@import "../../../Global_SCSS/base";

.intro-background {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 200px 0px 157px;
  background-color: #0f1921;
}

.intro-background-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 814px;
  max-width: 1626px;
}

.intro-text {
  width: 60%;
}

.white-styled-text {
  font-family: "Playfair Display";
  font-style: italic;
  font-size: 64px;
  line-height: 76px;
  font-weight: 400;
  color: #5c9452;
}

.green-styled-medium-text {
  font-family: "Playfair Display";
  font-size: 64px;
  line-height: 76px;
  font-weight: 400;
  color: #ffffff;
}

.small-white-text {
  width: 60%;
  font-size: 18px;
  line-height: 36px;
  font-family: "Roboto", sans-serif;
  color: #ffffff;
}

.intro-image-container {
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.intro-image {
  width: 100%;
  object-fit: contain;
}

@media screen and (min-width: 360px) and (max-width: 540px) {
  .intro-background {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 30px 0px 30px;
    background-color: #0f1921;
  }
  .intro-background-content {
    display: flex;
    justify-content: center;
    height: max-content;
    flex-direction: column;
    align-items: center;
  }
  .intro-text {
    width: 100%;
  }

  .small-white-text {
    width: 90%;
    font-size: 16px;
    line-height: 32px;
    color: #ffffff;
    font-family: "Roboto", sans-serif;
  }
  .green-styled-medium-text {
    font-family: "Playfair Display";
    font-size: 40px;
    line-height: 48px;
    font-weight: 200;
    color: #ffffff;
  }
  .white-styled-text {
    font-family: "Playfair Display";
    font-style: italic;
    font-size: 40px;
    line-height: 48px;
    font-weight: 200;
    color: #5c9452;
  }
  .intro-image-container {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
}

@media screen and (min-width: 280px) and (max-width: 350px) {
  .intro-background {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 30px 0px 30px;
    background-color: #0f1921;
  }
  .intro-background-content {
    display: flex;
    justify-content: center;
    height: max-content;
    flex-direction: column;
    align-items: center;
  }
  .intro-text {
    width: 100%;
  }

  .small-white-text {
    width: 90%;
    font-size: 16px;
    line-height: 32px;
    color: #ffffff;
    font-family: "Roboto", sans-serif;
  }
  .green-styled-medium-text {
    font-family: "Playfair Display";
    font-size: 40px;
    line-height: 48px;
    font-weight: 200;
    color: #ffffff;
  }
  .white-styled-text {
    font-family: "Playfair Display";
    font-style: italic;
    font-size: 40px;
    line-height: 48px;
    font-weight: 200;
    color: #5c9452;
  }
  .intro-image-container {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .small-white-text {
    width: 90%;
  }
  .intro-background {
    padding: 0px 160px;
  }
}
@media screen and (min-width: 1920px) {
  .intro-image {
    width: 87%;
  }
}

@media screen and (min-width: 1024px) {
  .intro-background {
    padding: 0px 85px;
  }
  .small-white-text {
    width: 90%;
  }
  .green-styled-medium-text {
    font-size: 43px;
    line-height: 50px;
  }
  .white-styled-text {
    font-size: 44px;
    line-height: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .intro-background {
    padding: 32px 80px 0px 80px;
  }

  .intro-background-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .intro-text {
    width: 100%;
  }
  .green-styled-medium-text {
    font-size: 48px;
    line-height: 54px;
  }
  .white-styled-text {
    font-size: 48px;
    line-height: 54px;
  }
  .small-white-text {
    width: 100%;
  }
}

@media only screen and (min-width: 1626px) {
  .small-white-text {
    width: 70%;
  }
}
