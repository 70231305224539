@import "../../../Global_SCSS/base";
.change-name-page {
  background-color: #4d5562;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}

.name-change-popup {
  background-color: #ffffff;
  height: auto;
  border-radius: 8px;
}

.name-change-title {
  padding: 3% 6%;
}

.name-change-fields {
  padding: 0 6%;
}

.name-change-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 4%;
  margin-top: 10%;
  padding: 4% 6%;
  border-top: 1px solid #d1d5db;
}
